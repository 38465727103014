import React from "react";
import Container from "react-bootstrap/Container";
import BlogPostCard from "../components/BlogPostCard";
import Hero from "../components/Hero";
import Layout from "../components/layout";

export const query = graphql`
  query BlogPageQuery {
    posts: allSanityPost(sort: { order: DESC, fields: publishedAt }) {
      nodes {
        id
        mainImage {
          asset {
            fluid(maxWidth: 1000) {
              ...GatsbySanityImageFluid
            }
          }
        }
        _rawExcerpt
        _rawBody
        slug {
          current
        }
        title
        subtitle
        publishedAt(formatString: "DD MMM YY")
      }
    }
  }
`;

const Blog = props => {
  const { data, errors } = props;
  const postNodes = data.posts.nodes.filter((nodes, i) => i != 0);
  const featured = data.posts.nodes[0];

  return (
    <Layout>
      <Hero
        image={featured.mainImage}
        heading={featured.title}
        superheading="Latest post"
        subheading={featured.subtitle}
        link={`/post/${featured.slug.current}`}
        linkText="Read now"
        height="67vh"
        className="mb-3"
      />
      <Container>
        {postNodes.map(postNode => (
          <BlogPostCard key={postNode.id} postNode={postNode} />
        ))}
      </Container>
    </Layout>
  );
};

export default Blog;
