import { Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import React from "react";
import styled from "styled-components";

const StyledJumbotron = styled.div.attrs({
  className: "position-relative",
  style: { height: "95vh" }
})`
  overflow: hidden;
`;

const JumbotronText = styled.div.attrs({
  className: "text-white text-center animated fadeUpIn pa-5"
})`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;

  a {
    color: white;
  }
`;

const JumbotronSubtitle = styled.div.attrs(props => ({
  className: `${props.headingSize} mb-1 mx-auto`
}))`
  text-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
  max-width: 20ch;

  font-family: "Headings";
`;


const JumbotronBody = (props) => {
  const {
    heading,
    subheading = null,
    headingSize = "display-4",
    subheadingSize = "h3",
    linkText = "",
    superheading = "",
    textAlign = "center"
  } = props
  return <JumbotronText className={`text-${textAlign}`}>
    <div
      style={{
        backgroundColor: "rgba(0,0,0,.3)",
        width: "100%",
        height: "100%",
        position: "absolute",
        filter: "blur(25px)",
        zIndex: -1
      }}
    ></div>

    {superheading && <div className="overline mb-1">{superheading}</div>}
    <JumbotronSubtitle headingSize={headingSize} textAlign={textAlign}>
      {heading}
    </JumbotronSubtitle>
    {subheading && <div className={`text-white mx-auto mb-2 ${subheadingSize}`} style={{maxWidth: '30ch'}}>{subheading}</div>}
        {linkText && linkText + " →"}
  </JumbotronText>
}

const Hero = props => {
  const {
    image,
    link = "",
    height = "90vh",
    className = "",
  } = props;
  return (
    <>
      <div className={className}>
        <BackgroundImage
          tag={StyledJumbotron}
          fluid={image && image.asset && image.asset.fluid}
          style={{
            height,
          }}
        >
          {link ? <Link to={ link} className="text-decoration-none">
            <JumbotronBody {...props} />
          </Link> : <JumbotronBody  {...props} />}
        </BackgroundImage>
      </div>
    </>
  );
};

export default Hero;
